import globalZh from '@/_common/locale/zh-CN';
import baseZh from '@/app/base/_common/locale/zh-CN';
import gatewayZh from '@/app/gateway/_common/locale/zh-CN';
import serviceZh from '@/app/service/_common/locale/zh-CN';
import monitorZh from '@/app/easeMonitor/_common/locale/zh-CN';
import stackZh from '@/app/easeStack/_common/locale/zh-Ch';
import homeZh from '@/app/home/_common/locale/zh-CN';
import middlewareZh from '@/app/easeMiddleware/_common/locale/zh-CN';
import loadZh from '@/app/easeLoad/_common/locale/zh-CN';
import tagsZh from '@/app/tags/_common/locale/zh-CN';
import tenantZh from '@/app/tenant/_common/locale/zh-CN';
import settingsZh from '@/app/settings/_common/locale/zh-CN';
import operationZh from '@/app/operation/_common/locale/zh-CN';
import cicdZH from '@/app/ease-cicd/_common/locale/zh-CN';
const appZh = {
  'app.resource.loading': '加载应用静态资源...',
  'app.page.resource.loading': '加载页面静态资源...',
  //Top Menu
  'app.topmenu.overview': '总览',
  'app.topmenu.traffic': '流量',
  'app.topmenu.service': '服务',
  'app.topmenu.monitor': '监控',
  'app.topmenu.stack': '部署',
  'app.topmenu.easeMiddleware': '中间件',
  'app.topmenu.easeLoad': '压测',
  'app.topmenu.settings': '设置',
  Operations: '运营',
  'app.topmenu.tags': '标签',

  //Second Menu
  'app.secondmenu.overall': '系统概况',
  'app.secondmenu.topology': '拓扑图',
  'app.secondmenu.topn': '排名',
  'app.secondmenu.monitor': '监控',
  'app.secondmenu.management': '管理',
  'app.secondmenu.mesh': '网格',
  'app.secondmenu.events': '事件',
  'app.secondmenu.report': '报告',
  'app.secondmenu.APIDocument': 'API文档',
  'app.secondmenu.tracing': '全链路追踪',
  'app.secondmenu.middleware': '中间件',
  'app.secondmenu.resource': '资源层',
  'app.secondmenu.dashboard': '仪表盘',
  'app.secondmenu.alert': '事件告警',
  'app.secondmenu.log': '日志',
  'app.secondmenu.deployment': '部署',
  'app.secondmenu.function': '函数',
  'app.secondmenu.permission': '权限',
  'app.secondmenu.auditLog': '审计日志',
  'app.secondmenu.notification': '通知',
  'app.secondmenu.history': '历史',
  'app.secondmenu.gpu': 'GPU',
  'app.secondmenu.vendor': '供应商',
  'app.secondmenu.spec': '规格',
  'app.secondmenu.stock': '库存',
  'app.secondmenu.Storage': '存储',
  'app.secondmenu.purchase': '购买',
  'app.secondmenu.purchased': '已购',
  'app.secondmenu.gpustorage': 'GPU存储',
  'app.secondmenu.billing': '计费',
  'app.secondmenu.usageRecord': '使用记录',
  'app.secondmenu.gpu.resources': 'GPU资源',

  'app.secondmenu.AlertRule': '告警规则',

  'app.secondmenu.kubernetes': 'Kubernetes',
  'app.secondmenu.application': '应用',
  'app.secondmenu.LOG': '日志',
  'app.secondmenu.Operation': '操作',
  'app.secondmenu.APMAgents': '监控代理',
  'app.secondmenu.Console': '控制台',
  'app.secondmenu.stack.manifest': '清单',
  'app.secondmenu.stack.dashboard': '仪表盘',
  'app.secondmenu.stack.history.version': '部署',
  'app.secondmenu.stack.event': '事件',

  'app.secondmenu.easeMiddleware': '中间件',
  'app.secondmenu.easeMiddleware.instance': '实例',
  'app.secondmenu.easeMiddleware.create.instance': '创建实例',
  'app.secondmenu.easeMiddleware.create.instance.host': '选择服务器',
  'app.secondmenu.easeMiddleware.update.instance': '实例详情',
  'app.secondmenu.easeMiddleware.app.detail': '应用详情',
  'app.secondmenu.easeMiddleware.host': '服务器',
  'app.secondmenu.easeMiddleware.create.host': '添加服务器',
  'app.secondmenu.easeMiddleware.update.host': '修改服务器',
  'app.secondmenu.easeMiddleware.detail.host': '服务器详情',
  'app.secondmenu.easeMiddleware.detail.host.AlertRuleForm': '告警规则表单',
  'app.secondmenu.easeMiddleware.host.tags': '添加标签',
  'app.secondmenu.easeMiddleware.service': '服务',
  'app.secondmenu.easeMiddleware.create.service': '创建服务',
  'app.secondmenu.easeMiddleware.service.version': '服务版本',
  'app.secondmenu.easeMiddleware.service.template': '配置模板',
  'app.secondmenu.easeMiddleware.service.spec': '服务规格',
  'app.secondmenu.easeMiddleware.create.service.spec': '创建服务规格',
  'app.secondmenu.easeMiddleware.update.service.spec': '修改服务规格',
  'app.secondmenu.easeMiddleware.tags': '标签管理',
  'app.secondmenu.easeMiddleware.tags.resources': '资源',
  'app.secondmenu.easeMiddleware.tags.tag.resources': '标签资源',
  'app.secondmenu.easeMiddleware.jumpServer': '跳板机',
  'app.secondmenu.easeMiddleware.jumpServers': '跳板机',
  'app.secondmenu.easeMiddleware.create.jumpServer': '添加跳板机',
  'app.secondmenu.easeMiddleware.update.jumpServer': '编辑跳板机',
  'app.secondmenu.easeMiddleware.detail.jumpServer': '跳板机详情',

  'app.secondmenu.deploy': '部署',
  'app.secondmenu.deploy.roles': '部署组件',
  'app.secondmenu.deploy.create.role': '新增部署组件',
  'app.secondmenu.deploy.update.role': '修改部署组件',
  'app.secondmenu.deployLogs': '日志',

  'app.secondmenu.manager': '管理',
  'app.secondmenu.manager.myworkspaces': '我的工作空间',
  'app.secondmenu.workspace': '工作空间',
  'app.secondmenu.workspace.overview': '总览',
  'app.secondmenu.workspace.case': '测试用例',
  'app.secondmenu.workspace.workflow': '工作流',
  'app.secondmenu.workspace.trafficmodel': '流量模型',
  'app.secondmenu.workspace.task': '任务',
  'app.secondmenu.workspace.analysis': '性能分析',

  'app.secondmenu.tags': '标签',
  'app.secondmenu.tags.management': '管理',
  'app.secondmenu.tags.resources': '资源',
  'app.secondmenu.tags.tag.resources': '标签资源',
  'app.secondmenu.host': '主机',

  'app.secondmenu.tenant': '租户',
  'app.secondmenu.application.app': '应用',
  'app.secondmenu.security': '安全',
  'app.secondmenu.manage': '管理',
  'app.secondmenu.doc': '文档',
  'app.secondmenu.services': '服务',

  TenantGPUInfo: '租户GPU信息',
  Statistic: '统计',

  //Third Level Menu
  'app.thirdmenu.resources': '资源',
  'app.thirdmenu.dashboard': '仪表盘',
  'app.thirdmenu.teams': '团队管理',
  'app.thirdmenu.team': '团队',
  'app.thirdmenu.members': '成员管理',
  'app.thirdmenu.sla': '服务等级协议',
  'app.thirdmenu.APIDocument': 'API文档',
  'app.thirdmenu.tracing': '服务调用',
  'app.thirdmenu.stack': '部署',
  'app.thirdmenu.service': '服务',
  'app.thirdmenu.services': '服务',
  'app.thirdmenu.http': '接口',
  'app.thirdmenu.jvm': 'JVM',
  'app.thirdmenu.jdbc': '数据库',
  'app.thirdmenu.redis': '缓存',
  'app.thirdmenu.kafka': 'Kafka',
  'app.thirdmenu.rabbitmq': 'RabbitMQ',
  'app.thirdmenu.gateway': '网关',
  'app.thirdmenu.topnservice': '服务排名',
  'app.thirdmenu.topnservices': '服务排名',
  'app.thirdmenu.config.server': '配置服务器',
  'app.thirdmenu.config.servers': '配置服务器',
  'app.thirdmenu.configServerInventory': '配置服务器清单',
  'app.thirdmenu.createConfigServer': '创建配置服务器',
  'app.thirdmenu.editConfigServer': '编辑配置服务器',
  'app.thirdmenu.configuration': '配置',
  'app.thirdmenu.configurations': '配置',
  'app.thirdmenu.governance': '治理',
  'app.thirdmenu.governances': '治理',
  'app.thirdmenu.records': '记录',
  'app.thirdmenu.mysql': 'MySQL',
  'app.thirdmenu.nginx': 'Nginx',
  'app.thirdmenu.infra': '基础设施',
  'app.thirdmenu.k8s': 'Kubernetes',
  'app.thirdmenu.customized': '定制大盘',
  'app.thirdmenu.hotevent': '热门事件',
  'app.thirdmenu.alertrule': '告警规则',
  'app.thirdmenu.alertrule.addRule': '创建告警规则',
  'app.thirdmenu.alertrule.editRule': '编辑告警规则',
  'app.thirdmenu.alertlog': '告警日志',
  'app.thirdmenu.applog': '应用日志',
  'app.thirdmenu.accesslog': '访问日志',
  'app.thirdmenu.elasticSearch': 'ElasticSearch',
  'app.thirdmenu.cluster': '集群',
  'app.thirdmenu.clusters': '集群',
  'app.thirdmenu.trafficGate': '流量网关',
  'app.thirdmenu.trafficGates': '流量网关',
  'app.thirdmenu.trafficGate2': '流量网关2',
  'app.thirdmenu.pipeline': '管道',
  'app.thirdmenu.pipelines': '流水线',
  'app.thirdmenu.pipeline2': '管道2',
  'app.thirdmenu.controller': '控制器',
  'app.thirdmenu.controllers': '控制器',
  'app.thirdmenu.controller2': '控制器2',
  'app.thirdmenu.filter': '过滤器',
  'app.thirdmenu.certificate': '证书',
  'app.thirdmenu.deploy': '发布',
  'app.thirdmenu.deploys': '发布',
  'app.thirdmenu.server': '服务',
  'app.thirdmenu.registry': '注册中心',
  'app.thirdmenu.registryMgmt': '镜像仓库',
  'app.thirdmenu.imageDetail': '镜像详情',

  'app.thirdmenu.registries': '注册中心',
  'app.thirdmenu.member': '节点',
  'app.thirdmenu.canary': '灰度',
  'app.thirdmenu.blueGreen': '蓝绿',
  'app.thirdmenu.function': '函数',
  'app.thirdmenu.users': '用户',
  'app.thirdmenu.auditLog': '审计日志',
  'app.thirdmenu.target': '目标',
  'app.thirdmenu.NotificationCenter': '通知中心',
  'app.thirdmenu.NotificationCenterDetail': '详情',
  'app.thirdmenu.PushManagement': '推送管理',
  'app.thirdmenu.PushLog': '推送日志',

  'app.thirdmenu.RuleTemplate': '规则模板',
  'app.thirdmenu.RuleTemplateForm': '表单',
  'app.thirdmenu.easeMiddleware.instance.clone': '克隆',
  'app.thirdmenu.easeMiddleware.instance.configVersion': '配置版本',

  'app.thirdmenu.stack.manifest': '部署清单',
  'app.thirdmenu.stack.dashboard.instance': '实例仪表盘',
  'app.thirdmenu.stack.create': '创建部署应用',
  'app.thirdmenu.stack.edit': '编辑部署应用',
  'app.thirdmenu.stack.version.differ': '版本比对',
  'app.thirdmenu.stack.dashboard': '仪表盘',
  'app.thirdmenu.stack.history.version': '历史版本',
  'app.thirdmenu.stack.rollback': '部署应用回滚',
  'app.thirdmenu.tenant': '租户',
  'app.thirdmenu.app.create': '创建应用',
  'app.thirdmenu.host.keyPair': '密钥对',
  'app.thirdmenu.host.keyPairs': '密钥对',

  'app.thirdmenu.Service.Form': '服务表单',
  'app.thirdmenu.Service.Instance': '服务实例',
  'app.thirdmenu.Service.Governance': '服务治理',
  'app.thirdmenu.Service.Observability': '服务可观测性',

  'app.thirdmenu.DataAccess': '数据接入',
  'app.thirdmenu.AppAccess': '应用接入',
  'app.thirdmenu.AddGoAppData': '添加Go应用数据',

  'global.toolBar.service': '服务',
  'global.toolBar.ZoneDomainService': '区/域/服务',
  'global.toolBar.Component': '组件',
  'global.toolBar.serviceInstance': '服务实例',
  'global.toolBar.chooseOneEaseStack': '部署应用',
  'global.toolBar.StackCluster': '部署应用集群',
  'global.toolBar.number': '数量',
  'global.toolBar.sortBy': '排序',
  'global.toolBar.redisMethod': 'Redis方法',
  'global.toolBar.clusterName': '集群名称',
  'global.toolBar.nodeName': '节点名称',
  'global.toolBar.InstanceName': '实例名称',
  'global.toolBar.servicePrefix': '服务名前缀',
  'global.toolBar.hostNamePrefix': '主机名前缀',
  'global.toolBar.timePicker': '时间选择器',
  'global.toolBar.end': '结束于 ',
  'global.toolBar.keywords': '关键词',
  'global.toolBar.podNamePrefix': ' 前缀',
  'global.toolBar.alertRuleName': '预警规则名称',
  'global.toolBar.labels': '标签',
  'global.toolBar.statusCode': '状态码',
  'global.toolBar.name': '名称',
  'global.toolBar.type': '类型',
  'global.toolBar.zone': '区',
  'global.toolBar.domain': '域',

  'global.top.topHttpRequests': 'HTTP请求排名',
  'global.top.topDatabaseRequests': '数据库请求排名',
  'global.top.topRedisMethodRequests': 'Redis方法请求排名',
  'global.top.topJVM': 'JVM排名',
  'global.top.topGateway': '网关排名',
  'global.top.topExchangeRoutingKey': '交换路由关键词排名',
  'global.top.topQueue': '队列排名',
  'global.top.topKafkaTopics': 'Kafka主题集排名',
  'global.top.topApi': 'API排名',

  'global.consumer': '消费者',
  'global.producer': '生产者',
  'global.relatedServices': '相关服务',
  'global.noRelatedServices': '没有相关服务',
  'global.noData': '没有数据',
  'global.anotherService': '请尝试选择上方工具栏中的其他服务。',
  'global.instances': '实例',
  'global.createService': '创建服务',
  'global.config': '配置',
  'global.binding': '绑定',

  'global.table.message': '消息',
  'global.table.service': '服务',
  'global.table.host': '主机',
  'global.table.times': '时间',
  'global.table.RepeatCount': '重复次数',
  'global.table.time': '时间',
  'global.table.level': '级别',
  'global.table.arrivalTime': '到达时间',
  'global.table.latestTriggerTime': '最近触发时间',
  'global.table.nextTriggerTime': '下次触发时间',
  'global.table.recoveryTime': '恢复时间',
  'global.table.details': '详情',
  'global.table.date': '日期',
  'global.table.status': '状态',
  'global.table.traceId': '跟踪Id',
  'global.table.url': '统一资源定位',
  'global.table.logTime': '日志产生时间',
  'global.table.stream': '流',
  'global.table.tag': '标签',
  'global.table.name': '名称',
  'global.table.port': '端口',
  'global.table.operation': '操作',
  'global.table.value': '值',
  'global.table.type': '类型',
  'global.table.instance': '实例',
  'global.table.description': '描述',
  'global.table.author': '作者',
  'global.table.actions': '操作',

  'global.newDashboard': '新建仪表盘',
  'global.editDashboard': '编辑仪表盘',
  'global.deleteDashboard': '删除仪表盘',
  'global.newPanel': '新建面板',
  'global.editPanel': '编辑面板',
  'global.deletePanel': '删除面板',
  'global.charts': '图表',
  'global.confirmPassword': '确认密码',
  'global.currentPassword': '当前密码',
  'global.newPassword': '新密码',
  'global.changePassword': '修改密码',
  'global.auditLog': '审计日志',
  'global.userManager': '用户管理',
  'global.logout': '退出登录',
  'global.dashboardName': '仪表盘名称',
  'global.onlyOwnerVisible': '仅所有者可见',

  'global.delDesc': '此操作将删除仪表盘和仪表盘下的每个面板。你想继续吗?',
  'global.filterMetrics': '按名称筛选指标',
  'global.PanelTitle': '面板标题',
  'global.ChartType': '图表类型',
  'global.serviceBinding': '服务绑定',
  'global.groupBy': '分组依据',
  'global.selectdMetrics': '选中的指标',
  'global.delPanel': '你确定要删除这个面板吗?',

  'global.confirmText': '您确定要删除当前规则吗?',

  'app.thirdmenu.Form': '表单',
  'app.thirdmenu.editPipeline': '编辑管道',
  'app.thirdmenu.controllerDetail': '控制器',
  'app.thirdmenu.history': '历史',
  'app.thirdmenu.historyDetail': '历史详情',
  'app.thirdmenu.createService': '创建服务',
  'app.thirdmenu.createRegistry': '创建注册中心',
  'app.thirdmenu.registryInventory': '注册中心清单',
  'app.thirdmenu.editRegistry': '编辑注册中心',
  'app.thirdmenu.serviceConfig': '服务配置',
  'app.thirdmenu.serviceInstances': '服务实例',
  'app.thirdmenu.serviceConfigItems': '服务配置项',

  'app.thirdmenu.Zone': '区',
  'app.thirdmenu.Zones': '区',
  'app.thirdmenu.ClusterForm': '集群表单',
  'app.thirdmenu.NamespaceForm': '命名空间表单',
  'app.thirdmenu.ServiceForm': '服务表单',
  'app.thirdmenu.Instances': '实例集',
  'app.thirdmenu.Domain': '域',
  'app.thirdmenu.Domains': '域',
  'app.thirdmenu.TenantForm': '租户表单',
  'app.thirdmenu.Ingress': '流量入口',
  'app.thirdmenu.Ingresses': '流量入口',
  'app.thirdmenu.IngressForm': '流量入口表单',
  'app.thirdmenu.TrafficControl': '流量控制',
  'app.thirdmenu.TrafficControls': '流量控制',
  'app.thirdmenu.TrafficControlForm': '流量控制表单',
  'app.thirdmenu.TrafficGroup': '流量分组',
  'app.thirdmenu.TrafficGroupForm': '流量分组表单',
  'app.thirdmenu.GlobalConfig': '全局配置',
  'app.thirdmenu.CanaryRelease': '灰度发布',
  'app.thirdmenu.CanaryReleases': '灰度发布',
  'app.thirdmenu.CanaryReleaseForm': '灰度发布表单',
  'app.thirdmenu.ShadowService': '影子服务',
  'app.thirdmenu.ShadowServices': '影子服务',
  'app.thirdmenu.ShadowServiceForm': '影子服务表单',

  'global.members': '成员',
  'app.secondmenu.document': '文档',
  'app.secondmenu.documents': '文档',
  'app.secondmenu.traceRecord': '追踪记录',
  'app.secondmenu.traceRecords': '追踪记录',
  'app.secondmenu.APM': '应用性能监控',

  'app.secondmenu.accountSecurity': '账户安全',
  'app.thirdmenu.accountSecurity.2fa': '双因认证',

  'app.tenant.startTime': '开始时间',
  'app.tenant.endTime': '结束时间',
  'app.tenant.keywords': '关键字',

  'global.attention': '注意',
  'app.secondmenu.store': '商店',
  'app.secondmenu.plansAndUsage': '计划与用量',
  ComparePlans: '计划对比',
  'app.secondmenu.order': '订单',
  'app.secondmenu.plan': '计划',
  'app.secondmenu.quota': '配额',
  'app.secondmenu.subscribe': '订阅',
  'app.secondmenu.balance': '余额',
  'app.thirdmenu.detail': '详情',
  'app.secondmenu.refund': '退款',

  'app.secondmenu.template': '模板',
  'app.thirdmenu.createTemplate': '创建模板',
  'app.thirdmenu.credentialManagement': '凭据管理',
  'app.thirdmenu.Referral': '邀请计划',
  'app.secondmenu.referral': '邀请计划',
  'app.thirdmenu.management': '已邀请用户',
  'app.thirdmenu.withdrawlMgmt': '提现管理',

  Detail: '详情',
  Billing: '计费',
  Orders: '订单',
  Plan: '计划',
  Plans: '计划',
  Quotas: '配额',
  Subscribes: '订阅',
  Balances: '余额',
  Tenants: '租户',
  Incomes: '收入',
  Template: '模板',

  'app.thirdmenu.rank': '排行',
  'app.secondmenu.start': '新建GPU实例',
  'app.secondmenu.instance': 'GPU实例',

  'app.topmenu.cicd': 'CI/CD',
  'app.secondmenu.pipeline': '流水线',
  'app.thirdmenu.myPipeline': '我的流水线',
  'app.thirdmenu.env': '环境变量',
  'app.thirdmenu.credentials': '我的凭据',
  'app.thirdmenu.pipelineAuthorization': '流水线授权',
  'app.thirdmenu.notification': '通知方式',

  'app.secondmenu.system.management': '系统管理',
  'app.thirdmenu.pipelineManagement': '流水线管理',
  'app.thirdmenu.pipelineTemplateManagement': '模板管理',
  'app.thirdmenu.env.management': '环境管理',
  'app.thirdmenu.pipelineEngine': '流水线引擎',
  'app.thirdmenu.FAQ': '常见问题',
  'app.thirdmenu.template.configs': '模板默认值',
  'app.thirdmenu.container.images': '我的镜像',
  'app.thirdmenu.scripts': '脚本管理',
  'app.thirdmenu.container.images.management': '镜像管理',
  'app.secondmenu.system.global.settings': '全局设置',
  'app.thirdmenu.create.pipeline': '创建流水线',
  'app.thirdmenu.edit.pipeline': '编辑流水线',

  'app.secondmenu.gpu.limit': 'GPU配额',
  'app.thirdmenu.inviter': '邀请人管理',
  'app.thirdmenu.contactUs': '联系我们',
  'app.secondmenu.fapiao': '发票管理',
  'app.thirdmenu.fapiao': '发票管理',
  'app.thirdmenu.announcement': '公告管理',
};

const messageZh = {
  ...globalZh,
  ...baseZh,
  ...appZh,
  ...gatewayZh,
  ...serviceZh,
  ...stackZh,
  ...monitorZh,
  ...homeZh,
  ...middlewareZh,
  ...loadZh,
  ...tagsZh,
  ...tenantZh,
  ...settingsZh,
  ...operationZh,
  ...cicdZH,
};
export default messageZh;
